/* eslint-disable @typescript-eslint/ban-types */
import QueryTags from '../types/QueryTags'
import { EndpointBuilder } from '@reduxjs/toolkit/dist/query/endpointDefinitions'
import { BaseQueryFn, FetchArgs, FetchBaseQueryError, FetchBaseQueryMeta } from '@reduxjs/toolkit/query/react'


const basePath = '/deliveries'

const deliveriesEndpoints = (builder: EndpointBuilder<
  BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError, {}, FetchBaseQueryMeta>, QueryTags, 'data'>) =>
  ({
    fetchDeliveries: builder.query<any, any>({
      query: () => `${basePath}`
    }),
    fetchPendingDriders: builder.query<any,any>({
      query: () => `/driver/pending-driver-approval`,
      providesTags: ['PendingDriverApproval']
    }),
    approveRider: builder.mutation<any, any>({
      query: ({id,transportTag,body}) => ({
        url: `/driver/approval/${id}?transportTag=${transportTag}`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: ['PendingDriverApproval']
    }),
    fetchDeliveriesByRiderId: builder.query<any, any>({
      query: (id) => `${basePath}/driver-orders/${id}`
    }),
    fetchTransportTypes: builder.query<any, any>({
      query: () => `/transport`
    })
  })

export default deliveriesEndpoints
