/* eslint-disable @typescript-eslint/ban-types */
import QueryTags from '../types/QueryTags'
import { EndpointBuilder } from '@reduxjs/toolkit/dist/query/endpointDefinitions'
import { BaseQueryFn, FetchArgs, FetchBaseQueryError, FetchBaseQueryMeta } from '@reduxjs/toolkit/query/react'


const authBasePath = '/vendors'

const vendorsEndpoints = (builder: EndpointBuilder<
  BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError, {}, FetchBaseQueryMeta>, QueryTags, 'data'>) =>
  ({
    fetchVendors: builder.query<any, string>({
      query: () => `${authBasePath}`,
      providesTags:['vendors']
    }),
    addVendor: builder.mutation<any, any>({
      query: (body) => ({
        url: `${authBasePath}`,
        method: 'post',
        body
      }),
      invalidatesTags: ['vendors']
    }),
    addVendorWallet: builder.mutation<any, any>({
      query: ({id, body}) => ({
        url: `${authBasePath}/${id}/wallet`,
        method: 'POST',
        body: body
      })
    }),
    fetchStoresList: builder.query<any, any>({
      query: (id) => `/vendor-stores/${id}`
    }),
    fetchVendorStores: builder.query<any, any>({
      query: (id) => `/vendor-stores/${id}`,
      providesTags: ['vendorsStore']
    }),
    createVendoreStore: builder.mutation<any, any>({
      query: (body) => ({
        url: '/vendor-stores',
        method: 'post',
        body
      }),
      invalidatesTags: ['vendorsStore']
    }),

    fetchVendorStaff: builder.query<any, any>({
      query: (id) => `${authBasePath}/${id}/staff`,
      providesTags:['vendors']
    }),

    createVendorStaff: builder.mutation<any, any>({
      query: ({id, body}) => ({
        url: `${authBasePath}/${id}/staff`,
        method: 'post',
        body: body
      }),
      invalidatesTags:['vendors']
    }),
    deleteVendorStaff: builder.mutation<any, any>({ 
      query: ({vendorId, staffId}) => ({
        url: `${authBasePath}/${vendorId}/staff/${staffId}`,
        method: 'delete'
      }),
      invalidatesTags:['vendors']
    }),
    updateVendorStaff: builder.mutation<any, any>({
      query: ({id, staffId, body}) => ({
        url: `${authBasePath}/${id}/staff/${staffId}`,
        method: 'put',
        body: body
      }),
      invalidatesTags:['vendors']
    }),

    fetchVendorProducts: builder.query<any, any>({
      query: (id) => `/products/${id}/vendor`,
      providesTags: ['vendorProduct']
    }),

    addVendorProduct: builder.mutation<any, any>({
      query: ({id, payload}) => ({
        url: `/products/${id}/vendor`,
        method: 'post',
        body: payload
      }),
      invalidatesTags: ['vendorProduct']
    }),
    
  })



export default vendorsEndpoints
