/* eslint-disable @typescript-eslint/ban-types */
import QueryTags from '../types/QueryTags'
import { EndpointBuilder } from '@reduxjs/toolkit/dist/query/endpointDefinitions'
import { BaseQueryFn, FetchArgs, FetchBaseQueryError, FetchBaseQueryMeta } from '@reduxjs/toolkit/query/react'


const basePath = '/roles'

const rolesEndpoints = (builder: EndpointBuilder<
  BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError, {}, FetchBaseQueryMeta>, QueryTags, 'data'>) =>
  ({
    fetchRoles: builder.query<any, string>({
      query: () => `${basePath}`,
      providesTags: ['roles']
    }),
    createARole: builder.mutation<any, any>({
      query: (body) => ({
        url: `${basePath}`,
        method: 'post',
        body
      }),
      invalidatesTags: ['roles']
    }),
    deleteARole: builder.mutation<any, any>({
      query: (id) => ({
        url: `${basePath}/${id}`,
        method: 'delete',
      }),
      invalidatesTags: ['roles']
    }),
    updateRole: builder.mutation<any, any>({
      query: ({ id, ...patch }) => ({
        url: `${basePath}/${id}`,
        method: 'put',
        body: patch
      }),
      invalidatesTags: ['roles']
    }),
    fetchBrands: builder.query<any, string>({
      query: () => `/brand`,
      providesTags: ['brands']
    }),

    createBrand: builder.mutation<any, any>({
      query: (body) => ({
        url: `/brand`,
        method: 'post',
        body
      }),
      invalidatesTags: ['brands']
    }),

    deleteBrand: builder.mutation<any, any>({
      query: (id) => ({
        url: `/brand/${id}`,
        method: 'delete',
      }),
      invalidatesTags: ['brands']
    }),

  })

export default rolesEndpoints
