const OrdersIcon = ({
  className,
  color,
}: {
  className?: string,
  color?: string,
}): JSX.Element => {
  return (
    <svg width="15"  className={className} height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M7.50001 0C9.98528 0 12 2.01472 12 4.5V5.25H15V6.74999H14.1248L13.5573 13.5622C13.5249 13.951 13.1999 14.25 12.8099 14.25H2.1901C1.80004 14.25 1.47508 13.951 1.44269 13.5622L0.874499 6.74999H0V5.25H3V4.5C3 2.01472 5.01471 0 7.50001 0ZM12.6195 6.74999H2.37975L2.88 12.75H12.1193L12.6195 6.74999ZM8.25001 8.24999V11.25H6.75001V8.24999H8.25001ZM5.25 8.24999V11.25H3.75V8.24999H5.25ZM11.25 8.24999V11.25H9.75001V8.24999H11.25ZM7.50001 1.5C5.89335 1.5 4.58169 2.76297 4.50367 4.35027L4.5 4.5V5.25H10.5V4.5C10.5 2.89335 9.23701 1.58169 7.64971 1.50367L7.50001 1.5Z" 
      fill={ color ? color : "#2A2F4E"} />
    </svg>

  )
}

export default OrdersIcon
