import { lazy } from "react";

const Orders = lazy(() => import("../pages/orders"));
const Warehouse = lazy(() => import("../pages/warehouse"));
const Analytics = lazy(() => import("../pages/analytics"));
const Users = lazy(() => import("../pages/customers"));
const Inventory = lazy(() => import("../pages/inventory"));
const Discount = lazy(() => import("../pages/discounts"));
const Vendors = lazy(() => import("../pages/vendors"));
const Categories = lazy(() => import("../pages/category"));
const Roles = lazy(() => import("../pages/settings/Roles"));
const SubCategory = lazy(() => import("../pages/subCategory"));
const Products = lazy(() => import("../pages/products"));
const Reports = lazy(() => import("../pages/reports"));
const Permissions = lazy(() => import("../pages/settings/Permissions"));
const Payments = lazy(() => import("../pages/payments"));
const Deliveries = lazy(() => import("../pages/deliveries"));
const OrderDetails = lazy(() => import("../pages/orders/OrderDetails"));
const VendorDetailsPage = lazy(() => import("../pages/vendors/vendorDetails"));
const AssignZone = lazy(() => import("../pages/authorization/AssignZone"));
const VendoreStore = lazy(
  () => import("../pages/vendors/vendorDetails/VendorStore/index")
);
const VendorStaffPage = lazy(
  () => import("../pages/vendors/vendorDetails/VendorStaff")
);
const VendorWallet = lazy(
  () => import("../pages/vendors/vendorDetails/VendorWallet")
);
const VendorProduct = lazy(
  () => import("../pages/vendors/vendorDetails/VendorProduct")
);
const Staff = lazy(() => import("../pages/staff"));
const PendingVendors = lazy(() => import("../pages/authorization/Vendors"));
const Riders = lazy(() => import("../pages/authorization/Riders"));
const PendingProducts = lazy(() => import("../pages/authorization/Products"));
const Brands = lazy(() => import("../pages/settings/Brands"));
const AssignZoneToStore = lazy(
  () => import("../pages/vendors/vendorDetails/VendorStore/AssignZone")
);
const RidersManagement = lazy(() => import("../pages/authorization/RiderManagement"));
const RiderInfo = lazy(() => import("../pages/authorization/RiderOrders"));
const Promotion = lazy(() => import("../pages/marketing"));

const routes = [
  { path: "/orders", element: Orders },
  { path: "/warehouse", element: Warehouse },
  { path: "/analytics", element: Analytics },
  { path: "/users", element: Users },
  { path: "/inventory", element: Inventory },
  { path: "/discounts", element: Discount },
  { path: "/vendors", element: Vendors },
  { path: "/categories", element: Categories },
  { path: "/roles", element: Roles },
  { path: "/sub-category", element: SubCategory },
  { path: "/products", element: Products },
  { path: "/reports", element: Reports },
  { path: "/permissions", element: Permissions },
  { path: "/payments", element: Payments },
  { path: "/deliveries", element: Deliveries },
  { path: "/orders/:id", element: OrderDetails },
  { path: "/vendors/:id", element: VendorDetailsPage },
  { path: "/vendors/:id/store", element: VendoreStore },
  { path: "/vendors/:id/store/:id", element: AssignZoneToStore },
  { path: "/vendors/:id/staff", element: VendorStaffPage },
  { path: "/vendors/:id/wallet", element: VendorWallet },
  { path: "/vendors/:id/product", element: VendorProduct },
  { path: "/staff", element: Staff },
  { path: "/pending-vendors", element: PendingVendors },
  { path: "/pending-riders", element: Riders },
  { path: "/pending-products", element: PendingProducts },
  { path: "/brands", element: Brands },
  { path: "/assign-rider/:id", element: AssignZone },
  { path: "/riders-management", element: RidersManagement },
  {path:"/riders-info/:id", element:RiderInfo},
  {path:"/promotions", element:Promotion}
];

export default routes;
