/* eslint-disable @typescript-eslint/ban-types */
import QueryTags from '../types/QueryTags'
import { EndpointBuilder } from '@reduxjs/toolkit/dist/query/endpointDefinitions'
import { BaseQueryFn, FetchArgs, FetchBaseQueryError, FetchBaseQueryMeta } from '@reduxjs/toolkit/query/react'
import { stringify } from 'querystring'


const basePath = '/driver'

const driverEndpoints = (builder: EndpointBuilder<
  BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError, {}, FetchBaseQueryMeta>, QueryTags, 'data'>) =>
  ({
    fetchDriver:builder.query<any, string>({
      query: () => `${basePath}`,
      providesTags: ['driver']
    }),
     approveARider: builder.mutation<any, any>({
      query: (id) => ({
        url: `${basePath}/approval/${id}/kjn`,
        method: 'PATCH',
      }),
      invalidatesTags: ['PendingDriverApproval']
    }),
    assignZoneDriver: builder.mutation<any, any>({
      query: ({zoneId,driverId}) => ({
        url: `zone/${zoneId}/assignDriver/${driverId}`,
        method: 'POST',
      }),
      invalidatesTags: ['driver']
    }),
     fetchDriversFullInfo:builder.query<any, string>({
      query: () => `${basePath}/v2`,
      providesTags: ['driver']
    }),
    fetchRiderById:builder.query<any, string>({
      query: (driverId) => `${basePath}/${driverId}`,
      providesTags: ['driver']
    })
  })

export default driverEndpoints
