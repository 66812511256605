import QueryTags from "../types/QueryTags";
import { EndpointBuilder } from "@reduxjs/toolkit/dist/query/endpointDefinitions";
import { BaseQueryFn, FetchArgs, FetchBaseQueryError, FetchBaseQueryMeta } from "@reduxjs/toolkit/query/react";

const basePath = "/promotion";

const promotionsEndpoints = (builder: EndpointBuilder<BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError, {}, FetchBaseQueryMeta>, QueryTags, "data">) => ({
  fetchPromotions: builder.query<any, any>({
    query: () => `${basePath}`,
    providesTags: ["promotion"]
  }),
  
  fetchPromotionById: builder.query<any, any>({
    query: id => `${basePath}/${id}/details`,
    providesTags: ["promotion"]
  })
});

export default promotionsEndpoints;
