/* eslint-disable @typescript-eslint/ban-types */
import QueryTags from "../types/QueryTags";
import { EndpointBuilder } from "@reduxjs/toolkit/dist/query/endpointDefinitions";
import {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
  FetchBaseQueryMeta,
} from "@reduxjs/toolkit/query/react";

const basePath = "/vendor-stores";

const storeEndpoints = (
  builder: EndpointBuilder<
    BaseQueryFn<
      string | FetchArgs,
      unknown,
      FetchBaseQueryError,
      {},
      FetchBaseQueryMeta
    >,
    QueryTags,
    "data"
  >
) => ({
  fetchZones: builder.query<any, string>({
    query: () => `/zone`,
  }),
  updateStore: builder.mutation<any, any>({
    query: ({ body, id, zoneId }) => ({
      url: `${basePath}/${id}?zoneId=${zoneId}`,
      method: "put",
      body,
    }),
    invalidatesTags: ["vendorsStore"],
  }),
});

export default storeEndpoints;
