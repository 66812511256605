/* eslint-disable @typescript-eslint/ban-types */
import QueryTags from "../types/QueryTags";
import { EndpointBuilder } from "@reduxjs/toolkit/dist/query/endpointDefinitions";
import {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
  FetchBaseQueryMeta,
} from "@reduxjs/toolkit/query/react";

const basePath = "/subcategory";

const subCategoriesEndpoints = (
  builder: EndpointBuilder<
    BaseQueryFn<
      string | FetchArgs,
      unknown,
      FetchBaseQueryError,
      {},
      FetchBaseQueryMeta
    >,
    QueryTags,
    "data"
  >
) => ({
  fetchSubCategories: builder.query<any, any>({
    query: ({ pageNumber, pageSize }) =>
      `${basePath}?pageNumber=${pageNumber}&pageSize=${pageSize}`,
    providesTags: ["subCategory"],
  }),
  addSubCategory: builder.mutation<any, any>({
    query: (body) => ({
      url: `${basePath}`,
      method: "post",
      body,
    }),
    invalidatesTags: ["subCategory"],
  }),
  fetchSubCategoryById: builder.query<any, any>({
    query: (id) => `${basePath}/${id}`,
  }),
  updateSubcategory: builder.mutation<any, any>({
    query: ({ id, body }) => ({
      url: `${basePath}/${id}`,
      method: "PUT",
      body,
    }),
    invalidatesTags: ["subCategory"],
  }),
  deleteSubCategory: builder.mutation<any, any>({
    query: (id) => ({
      url: `${basePath}/${id}`,
      method: "delete",
    }),
    invalidatesTags: ["subCategory"],
  }),
  editSubcategoryImage: builder.mutation<any, any>({
    query: ({ id, body }) => ({
      url: `${basePath}/${id}/image`,
      method: "PUT",
      body,
    }),
    invalidatesTags: ["subCategory"],
  }),
});

export default subCategoriesEndpoints;
