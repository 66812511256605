/* eslint-disable @typescript-eslint/ban-types */
import QueryTags from '../types/QueryTags'
import { EndpointBuilder } from '@reduxjs/toolkit/dist/query/endpointDefinitions'
import { BaseQueryFn, FetchArgs, FetchBaseQueryError, FetchBaseQueryMeta } from '@reduxjs/toolkit/query/react'


const basePath = '/category'

const categoriesEndpoints = (builder: EndpointBuilder<
  BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError, {}, FetchBaseQueryMeta>, QueryTags, 'data'>) =>
  ({
    fetchCategories: builder.query<any, string>({
      query: () => `${basePath}/all`,
      providesTags: ['category']
    }),
    addCategory: builder.mutation<any, any>({
      query: (body) => ({
        url: `${basePath}`,
        method: 'post',
        body
      }),
      invalidatesTags:['category']
    }),
    fetchSubCategoriesById: builder.query<any, any>({
      query: (id) => `${basePath}/${id}/subCategory`
    }),
    deleteCategory: builder.mutation<any, any>({
      query: (id) => ({
        url: `${basePath}/${id}`,
        method: 'delete',
      }),
      invalidatesTags:['category']
    }),
    updateCategory: builder.mutation<any, any>({
      query: ({id, body}) => ({
        url: `${basePath}/${id}`,
        method: 'PUT',
        body
      }),
      invalidatesTags:['category']
    }),
    fetchBarands: builder.query<any, any>({
      query: () => `/brand`
    }),
    editCategoryImage: builder.mutation<any, any>({
      query: ({id, body}) => ({
        url: `${basePath}/${id}/image`,
        method: 'PUT',
        body
      }),
      invalidatesTags:['category']
    }),
    fetchCategoryById: builder.query<any, any>({
      query: (id) => `${basePath}/${id}`
    })
  })

export default categoriesEndpoints
