import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import authEndpoints from "./auth";
import vendorsEndpoints from "./vendors";
import customersEndpoints from "./customers";
import categoriesEndpoints from "./categories";
import rolesEndpoints from "./roles";
import permissionsEndpoints from "./permissions";
import subCategoriesEndpoints from "./subCategories";
import inventoryEndpoints from "./inventory";
import productsEndpoints from "./products";
import ordersEndpoints from "./orders";
import paymentsEndpoints from "./payments";
import deliveriesEndpoints from "./deliveries";
import storesEndpoints from "./stores";
import driverEndpoints from "./drivers";
import promotionsEndpoints from "./promotion";
import dashboardEndpoints from "./reports";

const DcoreUrl = process.env.REACT_APP_D_CORE_URL;

const baseQuery = fetchBaseQuery({
  baseUrl: DcoreUrl,
  prepareHeaders: (headers, { endpoint }) => {
    const token = sessionStorage.getItem("refreshToken");
    if (token) {
      headers.set("Authorization", `Bearer ${token}`);
    }
    headers.set("Content-Type", "application/json");
    headers.set("accept", "application/json");
    headers.set("lang", "ENGLISH");
    return headers;
  },
});

const baseQueryIntercep: typeof baseQuery = async (args, api, extraOptions) => {
  let queryResult = await baseQuery(args, api, extraOptions);
  return queryResult;
};

const appApi = createApi({
  reducerPath: "data",
  baseQuery: baseQueryIntercep,
  tagTypes: [
    "subscribe",
    "vendors",
    "roles",
    "subCategory",
    "category",
    "inventory",
    "products",
    "vendorsStore",
    "vendorProduct",
    "PendingDriverApproval",
    "brands",
    "PendingProducts",
    "driver"
  ],
  endpoints: (builder) => ({
    ...authEndpoints(builder),
    ...vendorsEndpoints(builder),
    ...customersEndpoints(builder),
    ...categoriesEndpoints(builder),
    ...rolesEndpoints(builder),
    ...permissionsEndpoints(builder),
    ...subCategoriesEndpoints(builder),
    ...inventoryEndpoints(builder),
    ...productsEndpoints(builder),
    ...ordersEndpoints(builder),
    ...paymentsEndpoints(builder),
    ...deliveriesEndpoints(builder),
    ...storesEndpoints(builder),
    ...driverEndpoints(builder),
    ...promotionsEndpoints(builder),
    ...dashboardEndpoints(builder),
  }),
});

export default appApi;

export const {
  useLoginMutation,

  //vendors
  useFetchVendorsQuery,
  useAddVendorMutation,
  useAddVendorWalletMutation,
  useFetchStoresListQuery,
  useCreateVendoreStoreMutation,
  useFetchVendorStoresQuery,
  useCreateVendorStaffMutation,
  useDeleteVendorStaffMutation,
  useUpdateVendorStaffMutation,
  useFetchVendorProductsQuery,
  useAddVendorProductMutation,
  useFetchVendorStaffQuery,
  useFetchProductColorsQuery,
  useFetchProductSizesQuery,

  //customers
  useFetchCustomersQuery,

  //categories
  useFetchCategoriesQuery,
  useAddCategoryMutation,
  useLazyFetchSubCategoriesByIdQuery,
  useUpdateCategoryMutation,
  useDeleteCategoryMutation,
  useFetchBarandsQuery,
  useEditCategoryImageMutation,
  useLazyFetchCategoryByIdQuery,

  //roles management
  useFetchRolesQuery,
  useCreateARoleMutation,
  useDeleteARoleMutation,
  useUpdateRoleMutation,

  //brands management
  useFetchBrandsQuery,
  useCreateBrandMutation,
  useDeleteBrandMutation,

  //permissions
  useFetchPermissionsQuery,

  //sub categories
  useLazyFetchSubCategoriesQuery,
  useAddSubCategoryMutation,
  useUpdateSubcategoryMutation,
  useDeleteSubCategoryMutation,
  useEditSubcategoryImageMutation,
  useFetchSubCategoryByIdQuery,

  // inventory management
  useFetchInventoryQuery,
  useAddInventoryMutation,
  useUpdateInventoryMutation,
  useDeleteInventoryMutation,

  //products management
  useFetchProductsQuery,
  useDeleteProductMutation,
  useAddProductMutation,
  useUpdateProductMutation,
  useApproveProductMutation,
  useFetchPendingProductsQuery,

  //orders management
  useFetchOrdersQuery,
  useFetchOrderByIdQuery,
  useFetchOrderSummaryQuery,

  // payments management
  useFetchPaymentsQuery,

  //deliveries management
  useFetchDeliveriesQuery,
  useFetchPendingDridersQuery,
  useApproveRiderMutation,

  //stores
  useFetchZonesQuery,
  useUpdateStoreMutation,

  //drivers
  useFetchDriverQuery,
  useAssignZoneDriverMutation,
  useFetchDriversFullInfoQuery,
  useFetchRiderByIdQuery,
  useFetchDeliveriesByRiderIdQuery,
  
  
  // transport
  useFetchTransportTypesQuery,

  //promotions management
  useFetchPromotionByIdQuery,
  useFetchPromotionsQuery,

  //dashboard management
  useFetchDashboardOverviewQuery,
  useFetchSalesGrowthQuery,
  useFetchInventoryManagementQuery

} = appApi;
