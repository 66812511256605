const CustomersIcon = ({
  className,
  color,
}: {
  className?: string,
  color?: string,
}): JSX.Element => {
  return (

    <svg width="15" className={className} height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M7.5 6.875C9.22587 6.875 10.625 8.27413 10.625 10V13.75H9.375V10C9.375 9.00144 8.59444 8.18519 7.61019 8.12819L7.5 8.125C6.50144 8.125 5.68521 8.90556 5.62818 9.88981L5.625 10V13.75H4.375V10C4.375 8.27413 5.77411 6.875 7.5 6.875ZM3.4375 8.75C3.61178 8.75 3.78131 8.77038 3.94381 8.80888C3.83876 9.12125 3.77421 9.45125 3.7556 9.79331L3.75 10L3.75044 10.0535C3.67973 10.0285 3.60513 10.0117 3.52779 10.0043L3.4375 10C2.95019 10 2.54972 10.3718 2.50429 10.8472L2.5 10.9375V13.75H1.25V10.9375C1.25 9.72937 2.22938 8.75 3.4375 8.75ZM11.5625 8.75C12.7706 8.75 13.75 9.72937 13.75 10.9375V13.75H12.5V10.9375C12.5 10.4502 12.1282 10.0498 11.6528 10.0043L11.5625 10C11.453 10 11.3479 10.0187 11.2502 10.0532L11.25 10C11.25 9.58394 11.1823 9.18375 11.0572 8.80975C11.2187 8.77038 11.3882 8.75 11.5625 8.75ZM3.4375 5C4.30044 5 5 5.69956 5 6.5625C5 7.42544 4.30044 8.125 3.4375 8.125C2.57456 8.125 1.875 7.42544 1.875 6.5625C1.875 5.69956 2.57456 5 3.4375 5ZM11.5625 5C12.4254 5 13.125 5.69956 13.125 6.5625C13.125 7.42544 12.4254 8.125 11.5625 8.125C10.6996 8.125 10 7.42544 10 6.5625C10 5.69956 10.6996 5 11.5625 5ZM3.4375 6.25C3.26491 6.25 3.125 6.38994 3.125 6.5625C3.125 6.73506 3.26491 6.875 3.4375 6.875C3.61009 6.875 3.75 6.73506 3.75 6.5625C3.75 6.38994 3.61009 6.25 3.4375 6.25ZM11.5625 6.25C11.3899 6.25 11.25 6.38994 11.25 6.5625C11.25 6.73506 11.3899 6.875 11.5625 6.875C11.7351 6.875 11.875 6.73506 11.875 6.5625C11.875 6.38994 11.7351 6.25 11.5625 6.25ZM7.5 1.25C8.88069 1.25 10 2.36929 10 3.75C10 5.13071 8.88069 6.25 7.5 6.25C6.11929 6.25 5 5.13071 5 3.75C5 2.36929 6.11929 1.25 7.5 1.25ZM7.5 2.5C6.80963 2.5 6.25 3.05964 6.25 3.75C6.25 4.44036 6.80963 5 7.5 5C8.19037 5 8.75 4.44036 8.75 3.75C8.75 3.05964 8.19037 2.5 7.5 2.5Z"
        fill={color ? color :
          "#2A2F4E"} />
    </svg>

  )
}

export default CustomersIcon
