import { EndpointBuilder } from "@reduxjs/toolkit/dist/query/endpointDefinitions";
import { BaseQueryFn, FetchArgs, FetchBaseQueryError, FetchBaseQueryMeta } from "@reduxjs/toolkit/query/react";
import QueryTags from "../types/QueryTags";

const basePath = "/dashboard";

const dashboardEndpoints = (
  builder: EndpointBuilder<
    BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError, {}, FetchBaseQueryMeta>,
    QueryTags,
    "data"
  >
) => ({
  fetchSalesGrowth: builder.query<any, { startDate: string; endDate: string }>({
    query: ({ startDate, endDate }) => ({
      url: `${basePath}/salesGrowth`,
      params: { startDate, endDate },
    }),
    providesTags: ["dashboard"],
  }),

  fetchDashboardOverview: builder.query<any, { startDate: string; endDate: string }>({
    query: ({ startDate, endDate }) => ({
      url: `${basePath}/overview`,
      params: { startDate, endDate },
    }),
    providesTags: ["dashboard"],
  }),

  fetchInventoryManagement: builder.query<any, { startDate: string; endDate: string }>({
    query: ({ startDate, endDate }) => ({
      url: `${basePath}/inventoryManagement`,
      params: { startDate, endDate },
    }),
    providesTags: ["dashboard"],
  }),
});

export default dashboardEndpoints;
